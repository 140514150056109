import React, { useEffect, useState } from 'react'
import { EncounterEntry } from '../../../../../types/FHIRTypes/Entry'
import { Encounter } from '../../../../../types/FHIRTypes/Encounter'
import { useHistory } from 'react-router-dom'
import { Col, Row, Spinner } from 'react-bootstrap'
import { handleCodeableConcept, formatDateInTimeZone, handleReference } from '../../../../../utils/helpers'
import ProvenanceDetail from '../../Provenance'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import Narrative from '../../../DataTypes/Narrative'
import Period from '../../../DataTypes/Period'
import Identifier from '../../../DataTypes/Identifier'
import Reference from '../../../DataTypes/Reference'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import PatientReference from '../../../DisplayComponents/PatientReference'
import LocationReference from '../../../DisplayComponents/LocationReference'
import Profile from '../../../DisplayComponents/Profile'
import ResourceType from '../../../DisplayComponents/ResourceType'
import URL from '../../../DataTypes/URL'
import StringDisplay from '../../../DataTypes/String'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Code from '../../../DataTypes/Code'
import Coding from '../../../DataTypes/Coding'
import URI from '../../../DataTypes/URI'

interface EncounterDetailProps {
    encounters: EncounterEntry[];
    encounterDetailId: string | undefined;
    isFetching: boolean;
    dataExists: boolean;
    patientData: any;
}

const config: FieldConfig = {
    subject: { label: 'Patient', visibility: 'always' },
    status: { label: 'Status', visibility: 'always' },
    class: { label: 'Classification', visibility: 'always' },
    type: { label: 'Encounter Type', visibility: 'always' },
    participant: { label: 'Participant', visibility: 'always' },
    period: { label: 'Period', visibility: 'always' },
    reasonCode: { label: 'Reason Code', visibility: 'always' },
    reasonReference: { label: 'Reason Reference', visibility: 'conditional' },
    diagnosis: { label: 'Diagnosis', visibility: 'conditional' },
    hospitalization: { label: 'Hospitalization', visibility: 'conditional' },
    location: { label: 'Location', visibility: 'always' },
    text: { label: 'Summary', visibility: 'conditional' },
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}


const EncounterDetail: React.FC<EncounterDetailProps> = ({ encounters, encounterDetailId, isFetching, dataExists, patientData }) => {
    const [resource, setResource] = useState<Encounter | null>(null)

    const history = useHistory()

    useEffect(() => {
        if (dataExists) {
            const foundEncounter = encounters.find((e) => e.resource.id === encounterDetailId)

            if (foundEncounter) {
                setResource(foundEncounter.resource)
            } else {
                history.push('/404')
            }
        }
    }, [dataExists])

    return (
        <>
            {isFetching && (
                <>
                    <Spinner
                        as='span'
                        animation='border'
                        role='status'
                        aria-hidden='true'
                    />
                    Loading Member Encounters
                </>
            )}
            <dl className='dataContainer'>
                {resource ? (
                    <div>

                        <Row>
                            <ShowField field='subject' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.subject.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><PatientReference patient={resource.subject} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='status' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.status.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><Code value={resource.status} /></dd>
                                    {/* <dd>{encounter?.status || getDataAbsentReason(encounter, 'status')}</dd> */}
                                </Col >
                            </ShowField >
                            <ShowField field="class" config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.class.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><Coding value={resource.class} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='type' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.type.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{(resource.type || (resource as any)?._type[0]?.extension)?.map((item: any, index: number) => (
                                        <CodeableConcept key={`item-${index}`} data={item} dataExtension={item} />
                                    ))}</dd>
                                </Col>
                            </ShowField>

                            <ShowField field='participant' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.participant.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <>
                                        {resource?.participant?.map((participant, index) => (
                                            <dd key={`resource-participant-${index}`}>
                                                {participant.type?.map((type) => handleCodeableConcept(type)).join(', ') || 'Type'},
                                                {participant.period?.start ? `Start: ${formatDateInTimeZone(participant.period.start, 'MM/dd/yyyy', 'UTC')}` : ''}
                                                {participant.period?.end ? `End: ${formatDateInTimeZone(participant.period.end, 'MM/dd/yyyy', 'UTC')}` : ''},
                                                {handleReference(participant.individual)}
                                            </dd>
                                        ))}
                                    </>
                                </Col>
                            </ShowField>
                            <ShowField field='period' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.period.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{resource?.period ? <Period period={resource.period} /> : ''}</dd>
                                </Col >
                            </ShowField >
                            <ShowField field='reasonCode' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.reasonCode.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd> {(resource.reasonCode || ((resource as any)?._reasonCode && (resource as any)._reasonCode[0]?.extension))?.map((item: any, index: number) => (
                                        <CodeableConcept key={`item-${index}`} data={item} dataExtension={item} />
                                    ))}</dd>
                                </Col>
                            </ShowField>
                            <ShowField field='reasonReference' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.reasonReference.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        {
                                            resource?.reasonReference?.map((reason, index) => (
                                                <Reference key={`resource-reasonReference-${index}`} reference={reason} />
                                            ))
                                        }
                                    </dd>
                                </Col >
                            </ShowField >
                            <ShowField field='diagnosis' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.diagnosis.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    {resource?.diagnosis && resource.diagnosis.map((diagnosis, index) => (
                                        <dd key={`diagnosis-reference-${index}`}>
                                            {diagnosis.condition?.reference}
                                        </dd>
                                    ))}
                                </Col>
                            </ShowField>
                            <ShowField field='hospitalization' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.hospitalization.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    {
                                        resource?.hospitalization?.dischargeDisposition ? handleCodeableConcept(resource.hospitalization.dischargeDisposition) : ''
                                    }
                                </Col>
                            </ShowField>
                            <ShowField field='location' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.location.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    {resource?.location?.map((loc, index) => (
                                        <dd key={`resource-location-${index}`}>
                                            <LocationReference location={loc.location} />
                                        </dd>
                                    ))}
                                </Col>
                            </ShowField>
                            <ShowField field='text' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.text.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        <Narrative text={resource?.text} />
                                    </dd >
                                </Col >
                            </ShowField >
                        </Row >

                        <div className='footer'>
                            <hr />

                            <h6>FHIR Resource Metadata</h6>
                            <Row>
                                <ShowField field='resourceType' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.resourceType.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{<ResourceType resourceType={resource.resourceType} />}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='id' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><StringDisplay string={resource.id} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='meta' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.meta.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><MetaLastUpdated meta={resource.meta} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='profile' config={config} resource={resource.meta}>
                                    <Col sm={3}>
                                        <dt>{config.profile.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{<Profile profile={resource.meta?.profile} />}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='implicitRules' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.implicitRules.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><URI uri={resource.implicitRules} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='language' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.language.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><Code value={resource.language} /></dd>
                                    </Col >
                                </ShowField >
                                <ShowField field='identifier' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.identifier.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <Identifier identifiers={resource.identifier} />
                                    </Col>
                                </ShowField>
                            </Row >
                        </div >
                        <Row>
                            <Col sm={12}>
                                <ProvenanceDetail resourceName='Encounter' resourceId={encounterDetailId} />
                            </Col>
                        </Row>

                    </div >
                ) : (
                    <div className='text-center'>
                        {'No Encounters found for this member.'}
                    </div>
                )}
            </dl >
        </>
    )
}

export default EncounterDetail