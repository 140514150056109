import React, { FC } from 'react'

interface BooleanProps {
    value?: boolean
}

const Boolean: FC<BooleanProps> = ({ value }) => {
  return (
    <span>{value}</span>
  )
}

export default Boolean