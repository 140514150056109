import React, { FC } from 'react'
import { Extension } from '../../../../types/FHIRTypes/Extension'
import { Coding as FHIRCoding } from '../../../../types/FHIRTypes/Coding'
import { handleCodingOrExtension } from '../../../../utils/fhirUtils/handleCodingOrExtension'

interface CodingProps {
    value: FHIRCoding | Extension | undefined;
}

const Coding: FC<CodingProps> = ({ value }) => {
    const displayValue = handleCodingOrExtension(value)
    return (
        <>
            {displayValue || ''}
        </>
    )
}

export default Coding