import React, { FC } from 'react'

interface StringProps {
    string?: string 
}


const StringDisplay:FC<StringProps> = ({string}) => {
  const getStringDisplay = (): string => string ?? ''
  return (
    <div>{getStringDisplay()}</div>
  )
}

export default StringDisplay