import React, { FC } from 'react'
import { Spinner, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { formatCarePlanDataForTable } from '../../../../../utils/fhirUtils/carePlan'
import PaginationButtons from '../../../../PaginationButtons'
import { CarePlanBundle } from '../../../../../types/FHIRTypes/Bundle'
import CapitalizedText from '../../../DisplayComponents/CapitalizedText'
import Period from '../../../DataTypes/Period'
import Code from '../../../DataTypes/Code'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
interface Props {
  carePlanData: CarePlanBundle;
  isFetching: boolean;
  handleChangePage: (url: string) => void;
}



const CarePlanList: FC<Props> = ({ carePlanData, handleChangePage, isFetching }: Props) => {
  const patientCarePlans = formatCarePlanDataForTable(carePlanData)

  const nextLink = carePlanData && carePlanData.link.find((l: any) => l.relation === 'next')
  const previousLink = carePlanData && carePlanData.link.find((l: any) => l.relation === 'previous')
  return (
    <>
      <div className='d-flex flex-column mb-3 font-primary-color font-weight-bold'>
        {patientCarePlans.length > 0 &&
          <div className='mb-2'>
            <PaginationButtons
              backDisabled={!previousLink}
              nextDisabled={!nextLink}
              onBackClick={() => previousLink && handleChangePage(previousLink.url)}
              onNextClick={() => nextLink && handleChangePage(nextLink.url)}
            />
          </div>
        }
        {carePlanData?.total > 0 ? `${carePlanData.total.toLocaleString()} record(s) found` : ''}
      </div>
      <Table size='sm' hover>
        <thead>
          <tr>
            <th className='d-table-cell'>Category</th>
            <th className='d-table-cell'>Status</th>
            <th className='d-none d-lg-table-cell'>View Detail</th>
          </tr>
        </thead>
        <tbody>
          {
            isFetching ? (
              <tr>
                <td colSpan={6} rowSpan={15} className='d-flex'>
                  <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                  />
                  <span style={{ marginLeft: '10px' }}>Loading Care Plan Data...</span>
                </td>
              </tr>
            ) : patientCarePlans.length === 0 ? (
              <tr>
                <td colSpan={6} className='text-center'>No Care Plans found for this member.</td>
              </tr>
            ) : (
              patientCarePlans.map((carePlan: any) => (
                <tr key={carePlan.carePlanId}>
                    <td className='d-table-cell'> <Link to={`/patient/CarePlan/${carePlan.carePlanId}`}><CodeableConcept data={carePlan?.category[0]} dataExtension={(carePlan as any)?._category?.extension} /></Link></td>
                  <td className='d-table-cell'>
                    <Code value={carePlan.status} />
                  </td>
                  <td className='d-none d-lg-table-cell'><Link to={`/patient/CarePlan/${carePlan.carePlanId}`}>View Detail</Link></td>
                </tr >
              ))
            )
          }
        </tbody >
        <tfoot>
          {patientCarePlans.length > 0 &&
            <tr>
              <td scope="row" colSpan={5}>
                <PaginationButtons
                  backDisabled={!previousLink}
                  nextDisabled={!nextLink}
                  onBackClick={() => previousLink && handleChangePage(previousLink.url)}
                  onNextClick={() => nextLink && handleChangePage(nextLink.url)}
                />
              </td>
            </tr>
          }
        </tfoot>
      </Table >
    </>
  )
}

export default CarePlanList