import React from 'react'
import { SupportingInfo } from '../../../../types/FHIRTypes/ExplanationOfBenefit'
import { handleCodeableConcept } from '../../../../utils/helpers'
import Date from '../../DataTypes/Date'
import { getDataAbsentReason } from '../../../../utils/fhirUtils/getDataAbsentReason'
import Quantity from '../../DataTypes/Quantity'
import OrganizationReference from '../OrganizationReference'
import Coding from '../../DataTypes/Coding'
import StringDisplay from '../../DataTypes/String'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { Quantity as FHIRQuantity } from '../../../../types/FHIRTypes/Quantity'


interface EobSupportingInfoProps {
    supportingInfo?: SupportingInfo
}

const renderCodeableConcept = (code?: CodeableConcept) => {
    return code?.coding ? <>{handleCodeableConcept(code)}</> : <>{getDataAbsentReason(code?.extension || [])}</>
}

const renderStringValue = (code?: CodeableConcept, value?: string) => {
    return value ? <StringDisplay string={value} /> : <>{getDataAbsentReason(code?.extension || [])}</>
}

const renderQuantity = (code?: CodeableConcept, valueQuantity?: FHIRQuantity) => {
    return valueQuantity ? <Quantity quantity={valueQuantity} /> : <>{getDataAbsentReason(code?.extension || [])}</>
}

const renderDate = (code?: CodeableConcept, timingDate?: string) => {
    return timingDate ? <Date date={timingDate} /> : <>{getDataAbsentReason(code?.extension || [])}</>
}

const renderValueAndTime = (code?: CodeableConcept, valueString?: string, timingDate?: string, valueQuantity?: FHIRQuantity) => {
    if (code?.extension) return <>{getDataAbsentReason(code?.extension || [])}</>

    return (
        <div className='d-flex'>
            <span className='mr-2'>
                {timingDate && <Date date={timingDate} />}
            </span>
            <span className='mr-2'>
                {valueString && <StringDisplay string={valueString} />}
            </span>
            <span className='mr-2'>
                {code && handleCodeableConcept(code)}
            </span>
            <span className='mr-2'>
                {valueQuantity && <Quantity quantity={valueQuantity} />}
            </span>
            + Months
        </div>
    )
}

const renderSupportingInfoValue = (category: string, supportingInfo: SupportingInfo) => {
    switch (category) {
        case 'additionalbodysite':
        case 'admtype':
        case 'brandgenericindicator':
        case 'compoundcode':
        case 'drg':
        case 'discharge-status':
        case 'dawcode':
        case 'missingtoothnumber':
        case 'pointoforigin':
        case 'rxorigincode':
        case 'typeofbill':
            return renderCodeableConcept(supportingInfo.code)
        case 'admissionperiod':
        case 'clmrecvddate':
            return renderDate(supportingInfo.code, supportingInfo.timingDate)
        case 'ambulancetransportreason':
        case 'roundtrippurpose':
        case 'stretcherpurpose':
            return supportingInfo.reason ? <Coding value={supportingInfo.reason} /> : renderCodeableConcept(supportingInfo.code)
        case 'dropofflocation':
        case 'medicalrecordnumber':
        case 'patientaccountnumber':
        case 'pickuplocation':
            return renderStringValue(supportingInfo.code, supportingInfo.valueString)
        case 'dayssupply':
        case 'patientweight':
        case 'refillnum':
        case 'refillsAuthorized':
        case 'transportationdistance':
            return renderQuantity(supportingInfo.code, supportingInfo.valueQuantity)
        case 'orthodontics':
        case 'prosthesis':
            return renderValueAndTime(supportingInfo.code, supportingInfo.valueString, supportingInfo.timingDate, supportingInfo.valueQuantity)
        case 'servicefacility':
            return supportingInfo.valueReference ? <OrganizationReference organization={supportingInfo.valueReference} /> : <>{getDataAbsentReason([])}</>
        default:
            return <>{getDataAbsentReason([])}</>
    }
}


const EobSupportingInfo: React.FC<EobSupportingInfoProps> = ({ supportingInfo }) => {
    if (!supportingInfo) return null

    const category = supportingInfo.category?.coding?.[0].code || ''
    const value = renderSupportingInfoValue(category, supportingInfo)

    return (
        <div className='d-flex'>
            <span className='mr-2'>
                {handleCodeableConcept(supportingInfo.category)}:
            </span>
            {value}
        </div>
    )
}

export default EobSupportingInfo