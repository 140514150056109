import React from 'react'
import ProcedureDetail from './ProcedureDetail'
import ProcedureList from './ProcedureList'
import { ProcedureBundle } from '../../../../types/FHIRTypes/Bundle'
import { buildPatientName } from '../../../../utils/fhirUtils/patient'
import HumanName from '../../DataTypes/HumanName'

interface ProcedureProps {
    patientData: any;
    page: string | undefined;
    isFetching: boolean;
    procedureData: ProcedureBundle;
    procedureDetailId: string | undefined;
    handleChangePage: (url: string) => void;
}

const Procedure: React.FC<ProcedureProps> = ({ patientData, procedureDetailId, procedureData, isFetching, page, handleChangePage }) => {
    return (
        <>
            <h3>Procedures</h3>
            <h4>{patientData &&  <HumanName name={patientData.name[0]} /> }</h4>

            {
                procedureDetailId && page === 'Procedure' ? (
                    <ProcedureDetail
                        procedureData={procedureData}
                        isFetching={isFetching}
                        procedureDetailId={procedureDetailId} />
                ) : <ProcedureList
                    procedureData={procedureData}
                    handleChangePage={handleChangePage}
                    isFetching={isFetching}
                />
            }
        </>
    )
}

export default Procedure