import React, { FC } from 'react'
import { Quantity as FhirQuantity } from '../../../../types/FHIRTypes/Quantity'


interface FhirQuantityProps {
    quantity: FhirQuantity;
}


const Quantity: React.FC<FhirQuantityProps> = ({ quantity }) => {
    if (quantity) {
        return <span>{quantity.value}</span>
    }
    return null
}

export default Quantity