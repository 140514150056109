import React, { FC, useEffect, useState } from 'react'
import { ExplanationOfBenefitBundle } from '../../../../../types/FHIRTypes/Bundle'
import { Row, Col, Spinner } from 'react-bootstrap'
import { buildEOBInsurer, buildEOBPatientPayment } from '../../../../../utils/fhirUtils/eob'
import { handleCodeableConcept } from '../../../../../utils/helpers'
import useFhirResource from '../../../../../hooks/admin/useFhirResource'
import EobItem from '../EobItem'
import { appConfig } from '../../../../../assets/customizations/config'
import { useHistory } from 'react-router-dom'
import ProvenanceDetail from '../../Provenance'
import Identifier from '../../../DataTypes/Identifier'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import RelatedClaim from '../../../DisplayComponents/RelatedClaim'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import Simple from '../../../DisplayComponents/Simple'

import ResourceType from '../../../DisplayComponents/ResourceType'
import URL from '../../../DataTypes/URL'
import AdjudicationItem from '../EobAdjudication'
import EobTotal from '../EobTotal'
import PatientReference from '../../../DisplayComponents/PatientReference'
import Period from '../../../DataTypes/Period'
import Narrative from '../../../DataTypes/Narrative'
import Payee from '../../../DisplayComponents/Payee'
import Diagnosis from '../../../DisplayComponents/Diagnosis'
import EobAddItem from '../EobAddItem'
import CareTeamProvider from '../../../DisplayComponents/CareTeamProvider'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import DateTime from '../../../DataTypes/DateTime'
import Code from '../../../DataTypes/Code'
import PractitionerReference from '../../../DisplayComponents/PractitionerReference'
import EobSupportingInfo from '../../../DisplayComponents/EobSupportInfo'
import { ExplanationOfBenefit } from '../../../../../types/FHIRTypes/ExplanationOfBenefit'
import URI from '../../../DataTypes/URI'

interface Props {
  eobDetailId: string;
  eobData: ExplanationOfBenefitBundle;
  isFetching: boolean;
  patientData: any
}

const config: FieldConfig = {
  identifier: { label: 'EOB Identifier', visibility: 'always' },
  status: { label: 'EOB Status', visibility: 'always' },
  type: { label: 'EOB Type', visibility: 'always' },
  subType: { label: 'EOB Sub Type', visibility: 'conditional' },
  use: { label: 'Use', visibility: 'always' },
  patient: { label: 'Patient', visibility: 'always' },
  billablePeriod: { label: 'Billable Period', visibility: 'always' },
  created: { label: 'Created Date', visibility: 'always' },
  insurer: { label: 'Insurer', visibility: 'always' },
  provider: { label: 'Provider', visibility: 'always' },
  related: { label: 'Related Claim', visibility: 'always' },
  payee: { label: 'Payee', visibility: 'always' },
  outcome: { label: 'Outcome', visibility: 'always' },
  careteam: { label: 'Care Team', visibility: 'always' },
  supportingInfo: { label: 'Supporting Info', visibility: 'conditional' },

  diagnosis: { label: 'Diagnosis', visibility: 'always' },
  procedure: { label: 'Procedures Performed', visibility: 'conditional' },
  insurance: { label: 'Insurance', visibility: 'always' },
  item: { label: 'Line Items', visibility: 'always' },
  adjudication: { label: 'Adjudication', visibility: 'conditional' },
  addItem: { label: 'Additional Items', visibility: 'conditional' },
  total: { label: 'Total', visibility: 'conditional' },
  processNote: { label: 'Process Note', visibility: 'always' },
  payment: { label: 'Payment', visibility: 'always' },
  text: { label: 'Summary', visibility: 'conditional' },

  resourceType: { label: 'Resource Type', visibility: 'always' },
  id: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'always' },
  implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
  language: { label: 'Resource Language', visibility: 'conditional' },
}



const EobDetail: FC<Props> = ({ eobDetailId, eobData, isFetching, patientData }) => {

  const [eob, setEob] = useState<ExplanationOfBenefit | null>(null)
  const history = useHistory()

  useEffect(() => {
    if (!eobData) return

    const eobEntry = eobData?.entry?.find((eob) => eob.resource.id === eobDetailId)
    if (eobEntry) {
      setEob(eobEntry.resource)
    } else {
      history.push('/404')
    }
  }, [eobData])

  // org stuff
  const organization = eob?.contained?.[0]?.payor?.[0]?.reference ?? 'no insurer'
  const trimmedOrg = organization?.split('/')[1]
  const { data: orgData, refetch: getOrg, isFetching: fetchingOrgs } = useFhirResource(trimmedOrg, 'Organization')
  useEffect(() => {
    if (trimmedOrg) {
      getOrg()
    }
  }, [trimmedOrg])

  return (
    <>
      {
        isFetching && (
          <div className='d-flex dataContainer'>
            <Spinner
              as="span"
              animation="border"
              role="status"
              aria-hidden="true"
            />
            <span style={{ marginLeft: '10px' }}>Loading EOB Data...</span>
          </div>
        )
      }
      <dl className='dataContainer'>
        {
          eob ? (
            <div>
              <dl>
                <Row>
                  <ShowField field='identifier' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.identifier.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><Identifier identifiers={eob.identifier} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='status' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.status.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><Code value={eob.status} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='type' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.type.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><CodeableConcept data={eob.type} dataExtension={(eob as any)._type?.extension} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='subType' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.subType.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><CodeableConcept data={eob.subType} dataExtension={(eob as any)._subType?.extension} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='use' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.use.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><Code value={eob.use} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='patient' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.patient.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><PatientReference patient={eob.patient} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='billablePeriod' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.billablePeriod.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd> <Period period={eob.billablePeriod} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='created' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.created.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><DateTime dateTime={eob.created} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='insurer' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.insurer.label}</dt>
                    </Col>
                    <Col sm={9}>
                      {eob.insurer?.display ? (
                        <dd>{eob.insurer.display}</dd>
                      ) : fetchingOrgs ? (
                        <Spinner
                          as="span"
                          animation="border"
                          role="status"
                          aria-hidden="true"
                          size="sm"
                        />
                      ) : (
                        <dd>{buildEOBInsurer(orgData)}</dd>
                      )}
                    </Col>
                  </ShowField>
                  <ShowField field='provider' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.provider.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd>
                        <PractitionerReference practitioner={eob.provider} />
                      </dd>
                    </Col>
                  </ShowField>
                  <ShowField field='related' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.related.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd>
                        <RelatedClaim relatedClaim={eob.related} />
                      </dd>
                    </Col>
                  </ShowField>
                  <ShowField field='payee' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.payee.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><Payee payee={eob.payee} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='outcome' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.outcome.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><Code value={eob.outcome} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='careteam' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.careteam.label}</dt>
                    </Col>
                    <Col sm={9}>
                      {eob.careTeam?.map((teamMember: any, index: number) => (
                        <div key={`eob-careTeam-${index}`} className='mb-3'>
                          <CareTeamProvider
                            sequence={teamMember.sequence?.toString()}
                            providerReference={teamMember.provider?.reference}
                            role={teamMember.role ? handleCodeableConcept(teamMember.role) : ''}
                          />
                        </div>
                      ))}
                    </Col>
                  </ShowField>
                  <ShowField field='supportingInfo' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.supportingInfo.label}</dt>
                    </Col>
                    <Col sm={9}>
                      {
                        eob.supportingInfo?.map((supportingInfo, index) => (
                          <EobSupportingInfo
                            key={`eob-supporting-info-${index}`}
                            supportingInfo={supportingInfo}
                          />
                        ))
                      }
                    </Col>
                  </ShowField>
                  <ShowField field='diagnosis' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.diagnosis.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd><Diagnosis diagnosis={eob.diagnosis} /></dd>
                    </Col>
                  </ShowField>
                  <ShowField field='procedure' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.procedure.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <dd>
                        {
                          eob.procedure?.map((procedure: any) => procedure.procedureCodeableConcept?.text).join(', ')
                        }
                      </dd>
                    </Col>
                  </ShowField>
                  <ShowField field='insurance' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.insurance.label}</dt>
                    </Col>
                    <Col sm={9}>
                      {eob.insurance?.map((insuranceItem: any, index: number) =>
                        insuranceItem.focal && (
                          <div key={`eob-insurance-${index}`} className='mb-3'>
                            {insuranceItem.coverage?.reference}
                          </div>
                        )
                      )}
                    </Col>
                  </ShowField>
                  <ShowField field='item' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.item.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <EobItem patientEob={eob} />
                    </Col>
                  </ShowField>
                  <ShowField field='adjudication' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.adjudication.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <AdjudicationItem adjudications={eob.adjudication} />
                    </Col>
                  </ShowField>
                  <ShowField field='addItem' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.addItem.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <EobAddItem addItems={eob.addItem} />
                    </Col>
                  </ShowField>
                  <ShowField field='total' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.total.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <EobTotal total={eob.total} />
                    </Col>
                  </ShowField>
                  <ShowField field='processNote' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.processNote.label}</dt>
                    </Col>
                    <Col sm={9}>
                      <div>
                        {eob.processNote?.map((note: any, index: number) => (
                          <div key={`process-note-${index}`}>
                            <dd><Simple simple={note.text} /></dd>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </ShowField>
                  <ShowField field='payment' config={config} resource={eob}>
                    {
                      appConfig.use_eob_member_responsibility && (
                        <>
                          <Col sm={3}>
                            <dt>{config.payment.label}</dt>
                          </Col>
                          <Col sm={9}>
                            <dd>{buildEOBPatientPayment(eob)}</dd>
                          </Col>
                        </>
                      )
                    }
                  </ShowField>
                  <ShowField field='text' config={config} resource={eob}>
                    <Col sm={3}>
                      <dt>{config.text.label}</dt>
                    </Col>
                    <Col>
                      <dd><Narrative text={eob.text} /></dd>
                    </Col>
                  </ShowField>
                </Row>
                <div className='footer'>
                  <hr />
                  <h6>FHIR Resource Metadata</h6>
                  <Row>
                    <ShowField field='resourceType' config={config} resource={eob}>
                      <Col sm={3}>
                        <dt>{config.resourceType.label}</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>{<ResourceType resourceType={eob.resourceType} />}</dd>
                      </Col>
                    </ShowField>
                    <ShowField field='id' config={config} resource={eob}>
                      <Col sm={3}>
                        <dt>{config.id.label}</dt>
                      </Col>
                      <Col sm={9}>
                        <dd><Simple simple={eob.id} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='meta' config={config} resource={eob}>
                      <Col sm={3}>
                        <dt>{config.meta.label}</dt>
                      </Col>
                      <Col sm={9}>
                        <dd><MetaLastUpdated meta={eob.meta} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='profile' config={config} resource={eob}>
                      <Col sm={3}>
                        <dt>{config.profile.label}</dt>
                      </Col>
                      <Col sm={9}>
                        <dd>{eob.meta?.profile?.join(', ')}</dd>
                      </Col>
                    </ShowField>
                    <ShowField field='implicitRules' config={config} resource={eob}>
                      <Col sm={3}>
                        <dt>{config.implicitRules.label}</dt>
                      </Col>
                      <Col sm={9}>
                        <dd><URI uri={eob.implicitRules} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='language' config={config} resource={eob}>
                      <Col sm={3}>
                        <dt>{config.language.label}</dt>
                      </Col>
                      <Col sm={9}>
                        <dd><Code value={eob.language} /></dd>
                      </Col>
                    </ShowField>
                  </Row>
                </div>
                <Row>
                  <Col sm={12}>
                    <ProvenanceDetail resourceName='ExplanationOfBenefit' resourceId={eobDetailId} />
                  </Col>
                </Row>
              </dl>
            </div>
          ) : null
        }
      </dl>
    </>
  )
}

export default EobDetail