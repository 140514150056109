import React, { FC } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { buildPatientBirthday, buildPatientMemberId } from '../../../../utils/fhirUtils/patient'
import useEnvironment from '../../../../hooks/location/useEnviroment'
import { handleCodeableConcept, findExtensionByURI } from '../../../../utils/helpers'
import { Patient } from '../../../../types/FHIRTypes/Patient'
import HumanName from '../../DataTypes/HumanName'
import Address from '../../DataTypes/Address'
import ContactPoint from '../../DataTypes/ContactPoint'
import ProvenanceDetail from '../Provenance'
import PatientContact from '../../DisplayComponents/PatientContact'
import Narrative from '../../DataTypes/Narrative'
import Identifier from '../../DataTypes/Identifier'
import { FieldConfig } from '../../../../types/FieldConfig'
import ShowField from '../../DisplayComponents/FieldVisibilityWrapper'
import CapitalizedText from '../../DisplayComponents/CapitalizedText'
import Reference from '../../DataTypes/Reference'
import MetaLastUpdated from '../../DisplayComponents/LastUpdated'
import OrganizationReference from '../../DisplayComponents/OrganizationReference'
import Simple from '../../DisplayComponents/Simple'
import ResourceType from '../../DisplayComponents/ResourceType'
import URL from '../../DataTypes/URL'
import Date from '../../DataTypes/Date'
import StringDisplay from '../../DataTypes/String'
import CodeableConcept from '../../DataTypes/CodeableConcept'
import Code from '../../DataTypes/Code'
import Boolean from '../../DataTypes/Boolean'
import URI from '../../DataTypes/URI'

interface Props {
  patientData: Patient | undefined;
  loading: boolean;
  coverageData: any
}

const config: FieldConfig = {
  mostRecentMemberId: { label: 'Most Recent Member ID', visibility: 'always' },
  identifier: { label: 'Patient Identifier', visibility: 'always' },
  name: { label: 'Name', visibility: 'always' },
  gender: { label: 'Gender', visibility: 'always' },
  birthDate: { label: 'Birth Date', visibility: 'always' },
  address: { label: 'Address', visibility: 'always' },
  communication: { label: 'Communication Language', visibility: 'always' },
  telecom: { label: 'Contact Information', visibility: 'always' },
  race: { label: 'Race', visibility: 'always' },
  ethnicity: { label: 'Ethnicity', visibility: 'always' },
  birthSex: { label: 'Birthsex', visibility: 'always' },
  maritalStatus: { label: 'Marital Status', visibility: 'conditional' },
  multipleBirth: { label: 'Multiple Birth', visibility: 'conditional' },
  generalPractitioner: { label: 'General Practitioner', visibility: 'conditional' },
  managingOrganization: { label: 'Managing Organization', visibility: 'conditional' },
  contact: { label: 'Patient Contacts', visibility: 'conditional' },
  deceased: { label: 'Deceased', visibility: 'conditional' },
  text: { label: 'Patient Summary', visibility: 'conditional' },
  contained: { label: 'Contained', visibility: 'never' },
  extension: { label: 'Extension', visibility: 'never' },
  modifierExtension: { label: 'Modifier Extension', visibility: 'never' },
  link: { label: 'Link', visibility: 'never' },
  photo: { label: 'Photo', visibility: 'never' },

  // footer fields
  resourceType: { label: 'Resource Type', visibility: 'always' },
  resourceId: { label: 'Resource ID', visibility: 'always' },
  meta: { label: 'Resource Last Updated', visibility: 'always' },
  profile: { label: 'Resource Profile', visibility: 'conditional' },
  implicitRules: { label: 'Implicit Rules', visibility: 'conditional' },
  language: { label: 'Resource Language', visibility: 'conditional' },
  active: { label: 'Active Resource', visibility: 'conditional' }
}

const MemberProfile: FC<Props> = ({ patientData, loading, coverageData }: Props) => {
  const { data: environmentData } = useEnvironment()

  const patientRace = findExtensionByURI(patientData?.extension, 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race')
  const patientEthnicity = findExtensionByURI(patientData?.extension, 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity')
  const patientBirthSex = findExtensionByURI(patientData?.extension, 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex')

  return (
    <div>
      <h3>Member Profile</h3>
      <h4>
        {patientData?.name ? <HumanName name={patientData.name[0]} /> : ''}
      </h4>
      <section>
        <dl className={`dataContainer ${loading ? 'd-flex' : ''}`}>
          {
            loading ? (
              <>
                <Spinner
                  as='span'
                  animation='border'
                  role='status'
                  aria-hidden='true'
                />
                <span style={{ marginLeft: '10px' }}>Loading Member Profile</span>
              </>
            ) : (
              <Row>
                <ShowField field='mostRecentMemberId' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.mostRecentMemberId.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {
                        buildPatientMemberId(patientData, coverageData, environmentData?.isSingleTenantServer)
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='identifier' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.identifier.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      <Identifier identifiers={patientData?.identifier} />
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='name' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.name.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {
                        patientData?.name.map((name, index) => <HumanName key={`name-${index}`} name={name} showUse={patientData.name.length > 1} />)
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='gender' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.gender.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      <dd><Code value={patientData?.gender} /></dd>
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='birthDate' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.birthDate.label}</dt>
                  </Col>
                  <Col sm={8}>
                    {/* <dd><Simple simple={buildPatientBirthday(patientData)} /></dd>
                    <dd>{buildPatientBirthday(patientData)}</dd> */}
                    <dd><Date date={buildPatientBirthday(patientData)} /></dd>
                  </Col>
                </ShowField>
                <ShowField field='address' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.address.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {
                        patientData?.address?.map((address, index) => (
                          <Address
                            key={`address-${index}`}
                            address={address}
                            showUse={patientData.address && patientData.address.length > 1}
                          />
                        ))
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='communication' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.communication.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {
                        patientData?.communication?.map((communication) => handleCodeableConcept(communication.language)).join(', ')
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='telecom' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.telecom.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {patientData?.telecom?.map((telecom, index) => <ContactPoint key={`telecom-${index}`} contactPoint={telecom} />)}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='race' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.race.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {patientRace?.extension?.[1].valueString}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='ethnicity' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.ethnicity.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {patientEthnicity?.extension?.[1].valueString}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='birthSex' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.birthSex.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {patientBirthSex?.extension?.[1].valueString}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='maritalStatus' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.maritalStatus.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      <CodeableConcept data={patientData?.maritalStatus} dataExtension={(patientData as any)?._maritalStatus?.extension} />
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='multipleBirth' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.multipleBirth.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {
                        patientData?.multipleBirthBoolean !== undefined
                          ? <Simple simple={patientData.multipleBirthBoolean} />
                          : <Simple simple={patientData?.multipleBirthInteger || ''} />
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='generalPractitioner' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.generalPractitioner.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {
                        patientData?.generalPractitioner?.map((gp, index) => <Reference key={`gp-${index}`} reference={gp} />)
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='managingOrganization' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.managingOrganization.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      <OrganizationReference organization={patientData?.managingOrganization} />
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='contact' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.contact.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {patientData?.contact?.map((contact, index) => (
                        <PatientContact key={`contact-${index}`} contact={contact} />
                      ))}
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='deceased' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.deceased.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      {
                        patientData?.deceasedBoolean !== undefined
                          ? <CapitalizedText text={String(patientData.deceasedBoolean)} />
                          : patientData?.deceasedDateTime || ''
                      }
                    </dd>
                  </Col>
                </ShowField>
                <ShowField field='text' config={config} resource={patientData}>
                  <Col sm={4}>
                    <dt>{config.text.label}</dt>
                  </Col>
                  <Col sm={8}>
                    <dd>
                      <Narrative text={patientData?.text} />
                    </dd>
                  </Col>
                </ShowField>
                <Col sm={12} className='footer'>
                  <hr />
                  <h6>FHIR Resource Metadata</h6>
                  <Row>
                    <ShowField field='resourceType' config={config} resource={patientData}>
                      <Col sm={4}>
                        <dt>{config.resourceType.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd>{<ResourceType resourceType={patientData?.resourceType} />}</dd>
                      </Col>
                    </ShowField>
                    <ShowField field='resourceId' config={config} resource={patientData}>
                      <Col sm={4}>
                        <dt>{config.resourceId.label}</dt>
                      </Col>
                      <Col sm={8}>

                        <dd><StringDisplay string={patientData?.id} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='meta' config={config} resource={patientData}>
                      <Col sm={4}>
                        <dt>{config.meta.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><MetaLastUpdated meta={patientData?.meta} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='profile' config={config} resource={patientData}>
                      <Col sm={4}>
                        <dt>{config.profile.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd>
                          {
                            patientData?.meta?.profile?.map((profile, index) => (
                              <div key={`resource-profile-${index}`}>{profile}</div>
                            ))
                          }
                        </dd>
                      </Col>
                    </ShowField>
                    <ShowField field='language' config={config} resource={patientData}>
                      <Col sm={4}>
                        <dt>{config.language.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Code value={patientData?.language} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='implicitRules' config={config} resource={patientData}>
                      <Col sm={4}>
                        <dt>{config.implicitRules.label}</dt>
                      </Col>
                      <Col sm={8}>
                          <dd><URI uri={patientData?.implicitRules} /></dd>
                      </Col>
                    </ShowField>
                    <ShowField field='active' config={config} resource={patientData}>
                      <Col sm={4}>
                        <dt>{config.active.label}</dt>
                      </Col>
                      <Col sm={8}>
                        <dd><Boolean value={patientData?.active} /></dd>
                      </Col>
                    </ShowField>
                  </Row>
                </Col>

                <Col sm={12}>
                  <ProvenanceDetail resourceName='Patient' resourceId={patientData?.id} />
                </Col>
              </Row>
            )
          }
        </dl>
      </section>

    </div>
  )
}

export default MemberProfile