import React from 'react'
import { Adjudication } from '../../../../types/FHIRTypes/ExplanationOfBenefit'
import { handleCodeableConcept } from '../../../../utils/helpers'
import { getDataAbsentReason } from '../../../../utils/fhirUtils/getDataAbsentReason'
import { CodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { Row, Col } from 'react-bootstrap'

interface AdjudicationHeaderProps {
  adjudications?: Adjudication[]
}

const renderCodeableConcept = (code?: CodeableConcept) => {
  return code?.coding ? <>{handleCodeableConcept(code)}</> : <>{getDataAbsentReason(code?.extension || [])}</>
}

const renderDecimal = (value?: number) => {
  return value ? <>{value}</> : <>{getDataAbsentReason([])}</>
}

const renderAdjudicationHeaderValue = (category: string, adjudication: Adjudication) => {
  switch (category) {
    case 'allowedunits':
        return renderDecimal(adjudication.value)
    case 'adjustmentreason':
    case 'benefitpaymentstatus':
    case 'billingnetworkstatus':
    case 'renderingnetworkstatus':
        return renderCodeableConcept(adjudication.reason)
    default:
        return null
  }
}

const AdjudicationHeader: React.FC<AdjudicationHeaderProps> = ({ adjudications }) => {
  if (!adjudications || adjudications.length === 0) return null

  return (
    <Row>
      {adjudications.map((adjudication, index) => {
        const category = adjudication.category?.coding?.[0].code || ''
        const value = renderAdjudicationHeaderValue(category, adjudication)

        if (!value) return null

        return (
          <Col key={`adjudication-header-${index}`} xs={6}>
            <Row>
              <Col>
                {handleCodeableConcept(adjudication.category)}:
              </Col>
              <Col className='text-right'>
                {value}
              </Col>
            </Row>
          </Col>
        )
      })}
    </Row>
  )
}

export default AdjudicationHeader