import React, { useEffect } from 'react'
import { ProcedureBundle } from '../../../../../types/FHIRTypes/Bundle'
import { Col, Row, Spinner } from 'react-bootstrap'
import { ProcedureEntry } from '../../../../../types/FHIRTypes/Entry'
import { Procedure } from '../../../../../types/FHIRTypes/Procedure'
import { useHistory } from 'react-router-dom'
import ProvenanceDetail from '../../Provenance'
import { FieldConfig } from '../../../../../types/FieldConfig'
import ShowField from '../../../DisplayComponents/FieldVisibilityWrapper'
import Narrative from '../../../DataTypes/Narrative'
import Identifier from '../../../DataTypes/Identifier'
import Reference from '../../../DataTypes/Reference'
import MetaLastUpdated from '../../../DisplayComponents/LastUpdated'
import PatientReference from '../../../DisplayComponents/PatientReference'
import ResourceType from '../../../DisplayComponents/ResourceType'

import PeriodOrDateTime from '../../../DataTypes/PeriodOrDateTime'
import URL from '../../../DataTypes/URL'
import Profile from '../../../DisplayComponents/Profile'
import StringDisplay from '../../../DataTypes/String'
import CodeableConcept from '../../../DataTypes/CodeableConcept'
import Code from '../../../DataTypes/Code'
import URI from '../../../DataTypes/URI'

interface ProcedureDetailProps {
    procedureDetailId: string | undefined;
    procedureData: ProcedureBundle;
    isFetching: boolean;
}

const config: FieldConfig = {
    subject: { label: 'Patient', visibility: 'always' },
    status: { label: 'Status', visibility: 'always' },
    code: { label: 'Procedure Code', visibility: 'always' },
    encounter: { label: 'Encounter', visibility: 'conditional' },
    performed: { label: 'When Performed', visibility: 'always' },
    reasonCode: { label: 'Reason Code', visibility: 'conditional' },
    reasonReference: { label: 'Reason Reference', visibility: 'conditional' },
    text: { label: 'Summary', visibility: 'conditional' },

    // footer fields
    resourceType: { label: 'Resource Type', visibility: 'always' },
    id: { label: 'Resource ID', visibility: 'always' },
    meta: { label: 'Resource Last Updated', visibility: 'always' },
    profile: { label: 'Resource Profile', visibility: 'conditional' },
    language: { label: 'Resource Language', visibility: 'conditional' },
    implicitRules: { label: 'Resource Implicit Rules', visibility: 'conditional' },
    identifier: { label: 'Resource Identifier', visibility: 'conditional' },
}
const ProcedureDetail: React.FC<ProcedureDetailProps> = ({ procedureData, procedureDetailId, isFetching }) => {

    const [procedure, setProcedure] = React.useState<ProcedureEntry | undefined>(undefined)
    const history = useHistory()


    useEffect(() => {
        if (!procedureData) return

        const foundProcedure = procedureData?.entry?.find((procedure) => procedure.resource.id === procedureDetailId)
        if (foundProcedure) {
            setProcedure(foundProcedure)
        } else {
            history.push('/404')
        }
    }, [procedureData])

    const getProcedurePerformedDate = (procedure: Procedure) => {
        if (procedure.performedDateTime) {
            return new Date(procedure.performedDateTime).toLocaleDateString()
        }

        if (procedure.performedPeriod) {
            const startDate = procedure.performedPeriod.start ? new Date(procedure.performedPeriod.start).toLocaleDateString() : ''
            const endDate = procedure.performedPeriod.end ? new Date(procedure.performedPeriod.end).toLocaleDateString() : ''
            return endDate ? `${startDate} - ${endDate}` : startDate
        }

        if (procedure.performedString) {
            return procedure.performedString
        }

        if (procedure.performedAge) {
            return `${procedure.performedAge}`
        }

        if (procedure.performedRange) {
            return `${procedure.performedRange.low.value} - ${procedure.performedRange.high.value}`
        }

        return ''
    }

    const resource = procedure?.resource

    if (isFetching) {
        return (
            <div>
                <Spinner
                    as='span'
                    animation='border'
                    role='status'
                    aria-hidden='true'
                />
                Loading Procedure...
            </div>
        )
    }
    return (
        <>
            <dl className='dataContainer'>
                {resource ? (
                    <div>

                        <Row>
                            <ShowField field='subject' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.subject.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><PatientReference patient={resource.subject} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='status' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.status.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><Code value={resource.status} /></dd>
                                </Col>
                            </ShowField>
                            <ShowField field='code' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.code.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd><CodeableConcept data={resource?.code} dataExtension={(resource as any)?._code?.extension} /></dd>
                                </Col>
                            </ShowField>

                            <ShowField field='encounter' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.encounter.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        <Reference reference={resource.encounter} />
                                    </dd>
                                </Col>
                            </ShowField>
                            <ShowField field='performed' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.performed.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{<PeriodOrDateTime period={resource.performedPeriod} dateTime={resource.performedDateTime} />}</dd>
                                </Col>
                            </ShowField>
                            <ShowField field='reasonCode' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.reasonCode.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{(resource.reasonCode || (resource as any)?._reasonCode[0]?.extension)?.map((item: any, index: number) => (
                                        <CodeableConcept key={`item-${index}`} data={item} dataExtension={item} />
                                    ))}</dd>
                                </Col>
                            </ShowField>
                            <ShowField field='reasonReference' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.reasonReference.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>
                                        {
                                            resource.reasonReference?.map((reason, index) => (
                                                <Reference key={`reasonReference-${index}`} reference={reason} />
                                            ))
                                        }
                                    </dd>
                                </Col>
                            </ShowField>
                            <ShowField field='text' config={config} resource={resource}>
                                <Col sm={3}>
                                    <dt>{config.text.label}</dt>
                                </Col>
                                <Col sm={9}>
                                    <dd>{resource?.text && <Narrative text={resource.text} />}</dd>
                                </Col>
                            </ShowField>
                        </Row>

                        <div className='footer'>
                            <hr />

                            <h6>FHIR Resource Metadata</h6>
                            <Row>
                                <ShowField field='resourceType' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.resourceType.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{<ResourceType resourceType={resource.resourceType} />}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='id' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.id.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><StringDisplay string={resource.id} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='meta' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.meta.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><MetaLastUpdated meta={resource.meta} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='profile' config={config} resource={resource.meta}>
                                    <Col sm={3}>
                                        <dt>{config.profile.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd>{<Profile profile={resource.meta?.profile} />}</dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='language' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.language.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><Code value={resource.language} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='implicitRules' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.implicitRules.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <dd><URI uri={resource.implicitRules} /></dd>
                                    </Col>
                                </ShowField>
                                <ShowField field='identifier' config={config} resource={resource}>
                                    <Col sm={3}>
                                        <dt>{config.identifier.label}</dt>
                                    </Col>
                                    <Col sm={9}>
                                        <Identifier identifiers={resource.identifier} />
                                    </Col>
                                </ShowField>


                            </Row>
                        </div>
                        <Row>
                            <Col sm={12}>
                                <ProvenanceDetail resourceName='Procedure' resourceId={procedureDetailId} />
                            </Col>
                        </Row>

                    </div>
                ) : (
                    <div className='text-center'>
                        {' No Procedures found for this member.'}
                    </div>
                )}
            </dl>
        </>
    )
}

export default ProcedureDetail