import { useQuery } from 'react-query'
import request from 'superagent'
import { useEffect, useState } from 'react'
import { getApiServer } from '../../utils/domain'
import { useHistory } from 'react-router-dom'

export default function useUserWithRole() {
  const history = useHistory()
  const [role, setRole] = useState('')
  const [loggedOut, setLoggedOut] = useState(false)

  const { data: user, refetch, isError, isStale, status, isLoading, remove } = useQuery(
    'user',
    async () => {
      const foundUser = await request
        .get(`${getApiServer()}/auth/user`)
        .set('Accept', 'application/json')
        .withCredentials()
      return foundUser.body
    },
    {
      staleTime: 3600000,
      cacheTime: 3600000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
    }
  )

  useEffect(() => {
    if (user && user.role) {
      setRole(user.role)
    }
  }, [user])

  useEffect(() => {
    if (loggedOut) {
      history.push('/login')
    }
  }, [loggedOut, role, history])

  const removeUser = () => {
    setRole('member')
    setLoggedOut(true)
    remove()
  }

  return {
    user,
    role,
    setRole,
    refetch,
    isError,
    isStale,
    status,
    removeUser,
    isLoading,
    loggedOut,
  }
}