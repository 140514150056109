import React, { FC } from 'react'

interface CodeProps {
  value: string | string[] | undefined
}

const Code: FC<CodeProps> = ({ value }) => {
  if (!value) {
    return null
  }

  return (
    <div>
      {Array.isArray(value) ? (
        value.map((val, index) => (
          <React.Fragment key={index}>
            {val}
            <br />
          </React.Fragment>
        ))
      ) : (
        <span>{value}</span>
      )}
    </div>
  )
}

export default Code