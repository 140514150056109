import React, { FC } from 'react'
import ConditionList from './ConditionsList'
import ConditionDetail from './ConditionsDetail'
import { buildPatientName } from '../../../../utils/fhirUtils/patient'
import HumanName from '../../DataTypes/HumanName'

interface Props {
    conditionData: any;
    patientData: any;
    conditionDetailId: string | undefined;
    page: string | undefined;
    isFetching: boolean;
    handleChangePage: (url: string) => void;
}

const Conditions: FC<Props> = ({ conditionData, patientData, conditionDetailId, page, isFetching, handleChangePage }: Props) => {
    return (
        <div>
            <h3>Conditions</h3>
            <h4>{patientData && <HumanName name={patientData.name[0]} />}</h4>
            <>
                {
                    conditionDetailId && page === 'Condition' ? (
                        <ConditionDetail
                            conditionData={conditionData}
                            isFetching={isFetching}
                            conditionDetailId={conditionDetailId}
                        />
                    ) :
                        <ConditionList
                            conditionData={conditionData}
                            patientData={patientData}
                            isFetching={isFetching}
                            handleChangePage={handleChangePage}
                        />
                }
            </>
        </div>
    )
}

export default Conditions