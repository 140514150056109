import React from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { handleReference } from '../../../../utils/helpers'

interface ReferenceProps {
    reference?: FHIRReference
}

const Reference: React.FC<ReferenceProps> = ({ reference }) => {
    if (!reference) return null

    return (
        <div>{handleReference(reference)}</div>
    )
}

export default Reference