import React, { FC } from 'react'
import { Extension } from '../../../../types/FHIRTypes/Extension'
import { CodeableConcept as FhirCodeableConcept } from '../../../../types/FHIRTypes/CodeableConcept'
import { Coding } from '../../../../types/FHIRTypes/Coding'
import { handleExtension } from '../../../../utils/helpers'
import { getDataAbsentReason } from '../../../../utils/fhirUtils/getDataAbsentReason'

interface CodeableConceptProps {
    data?: FhirCodeableConcept | FhirCodeableConcept[]
    dataExtension?: Extension | Extension[]
}

const CodeableConcept: FC<CodeableConceptProps> = ({ data, dataExtension }) => {
    if (!data && !dataExtension) return null

    const processData = (data: any): string[] => {
        const values: string[] = []

        // if (data.text) values.push(${data.text}`)

        // data.coding?.forEach((coding: Coding) => {
        //     const codingParts = [coding.display, coding.code, coding.system].filter(Boolean)
        //     if (codingParts.length > 0) {
        //         values.push(codingParts.join(' '))
        //     }
        // })

        if (data.text) {
            values.push(data.text) 
        } else if (data.coding) {
            data.coding.forEach((coding: Coding) => {
                if (coding.display) {
                    values.push(coding.display)
                } else if (coding.code) {
                    values.push(coding.code)
                }
            })
        }

        if (data.extension) {
            const absentReason = getDataAbsentReason(data.extension)
            absentReason
                ? values.push(`Data absent reason: ${absentReason}`)
                : values.push(handleExtension(data.extension))
        }

        return values
    }

    const processExtensions = (extensions: Extension | Extension[]): string[] => {
        const values: string[] = []
        const absentReason = getDataAbsentReason(extensions)
        if (absentReason) values.push(`Data absent reason: ${absentReason}`)
        return values
    }

    const displayValues = [
        ...(data ? processData(data) : []),
        ...(dataExtension ? processExtensions(dataExtension) : []),
    ]

    return (
        <div>
            {displayValues.map((value, index) => (
                <div key={index} dangerouslySetInnerHTML={{ __html: value }} />
            ))}
        </div>
    )
}

export default CodeableConcept