import React, { useEffect } from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { Spinner } from 'react-bootstrap'
import HumanName from '../../DataTypes/HumanName'
import { Patient } from '../../../../types/FHIRTypes/Patient'
import { UseQueryResult } from 'react-query'
import Reference from '../../DataTypes/Reference'

interface PatientReferenceProps {
    patient?: FHIRReference
}

const PatientReference: React.FC<PatientReferenceProps> = ({ patient }) => {
    if (!patient) return null

    const reference = patient?.reference?.split('/')
    const patientId = reference?.[1] || ''

    const {
        data: patientData,
        refetch: getPatient,
        isFetching: fetchingPatient,
        isLoading: loadingPatient
    }: UseQueryResult<Patient, Error> = useFhirResourceById(patientId, 'Patient')

    useEffect(() => {
        if (patient) {
            getPatient()
        }
    }, [patient])

    if (fetchingPatient || loadingPatient) {
        return <Spinner animation="border" role="status" size='sm' />
    }

    const { name } = patientData || {}

    return (
        <div className="d-flex">
            {name ? (
                <HumanName name={name[0]} />
            ) : (
                <Reference reference={patient} />
            )}
        </div>
    )
}

export default PatientReference