import React, { useEffect } from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { UseQueryResult } from 'react-query'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { Spinner } from 'react-bootstrap'
import { Practitioner } from '../../../../types/FHIRTypes/Practitioner'
import HumanName from '../../DataTypes/HumanName'
import Reference from '../../DataTypes/Reference'

interface PractitionerReferenceProps {
    practitioner?: FHIRReference
}

const PractitionerReference: React.FC<PractitionerReferenceProps> = ({ practitioner }) => {
    if (!practitioner) return null

    const reference = practitioner?.reference?.split('/')
    const practitionerId = reference?.[1] || ''

    const {
        data: practitionerData,
        refetch: getPractitioner,
        isFetching: fetchingPractitioner,
        isLoading: loadingPractitioner
    }: UseQueryResult<Practitioner, Error> = useFhirResourceById(practitionerId, 'Practitioner')

    useEffect(() => {
        if (practitioner) {
            getPractitioner()
        }
    }, [practitioner])

    if (fetchingPractitioner || loadingPractitioner) {
        return <Spinner animation="border" role="status" size='sm' />
    }

    return (
        <div>
            {
                practitionerData?.name ? <HumanName name={practitionerData.name[0]} /> : <Reference reference={practitioner} />
            }
        </div>
    )
}

export default PractitionerReference