import React, { FC } from 'react'
import EobList from './EobList'
import EobDetail from './EobDetail'
import { ExplanationOfBenefitBundle } from '../../../../types/FHIRTypes/Bundle'
import { buildPatientName } from '../../../../utils/fhirUtils/patient'
import HumanName from '../../DataTypes/HumanName'

interface Props {
  patientData: any;
  eobData: ExplanationOfBenefitBundle;
  eobDetailId: string | undefined;
  page: string | undefined;
  isFetching: boolean;
  handleChangePage: (url: string) => void;
}

const Eobs: FC<Props> = ({ patientData, eobData, eobDetailId, page, isFetching, handleChangePage }) => {
  return (
    <div>
      <h3>Explanation of Benefits (EOBs)</h3>
      <h4>{patientData &&  <HumanName name={patientData.name[0]} /> }</h4>
      <section>
      {/* <h3>{eobDetailId ? 'EOB Detail' : 'EOBs'}: {patientData ? buildPatientName(patientData.name) : ''} {buildPatientId(patientData)}</h3> */}
      {
        eobDetailId && page === 'ExplanationOfBenefit'
          ? (
            <EobDetail
              eobDetailId={eobDetailId}
              eobData={eobData}
              isFetching={isFetching}
              patientData={patientData}
            />
          )
          : (
            <EobList
              eobData={eobData}
              handleChangePage={handleChangePage}
              isFetching={isFetching}
            />
          )
      }
    </section>
    </div>
  )
}
export default Eobs