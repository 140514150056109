import React, { useEffect } from 'react'
import { Reference as FHIRReference } from '../../../../types/FHIRTypes/Reference'
import { UseQueryResult } from 'react-query'
import { Location } from '../../../../types/FHIRTypes/Location'
import useFhirResourceById from '../../../../hooks/admin/useFhirResourceById'
import { Spinner } from 'react-bootstrap'
import Reference from '../../DataTypes/Reference'

interface LocationReferenceProps {
    location?: FHIRReference
}

const LocationReference: React.FC<LocationReferenceProps> = ({ location }) => {
    if (!location) return null

    const reference = location?.reference?.split('/')
    const locationId = reference?.[1] || ''

    const {
        data: locationData,
        refetch: getLocation,
        isFetching: fetchingLocation,
        isLoading: loadingLocation
    }: UseQueryResult<Location, Error> = useFhirResourceById(locationId, 'Location')

    useEffect(() => {
        if (location) {
            getLocation()
        }
    }, [location])

    if (fetchingLocation || loadingLocation) {
        return <Spinner animation="border" role="status" size='sm' />
    }

    return (
        <div className='d-flex'>
            {
                locationData?.name
                    ? locationData.name
                    : (
                        <div className='dark-gray-text'>
                            <Reference reference={location} />
                        </div>
                    )
            }
        </div>
    )
}

export default LocationReference