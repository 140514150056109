import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import useUserWithRole from '../../hooks/authentication/useUserWithRole'
import Loader from '../../components/Loader'
import useUserAuthenticated from '../../hooks/authentication/useUserAuthenticated'

const isAuthenticated = (ComposedComponent: React.ComponentType<any>): React.ComponentType<any> => {
  const Authenticated = (props: any) => {
    const { data: authenticated, refetch: checkIfAuthenticated, isError: authenticatedError } = useUserAuthenticated()
    const { isStale, refetch: refetchUser, status, removeUser } = useUserWithRole()

    useEffect(() => {
      const handleFocus = () => {
        checkIfAuthenticated()
      }

      checkIfAuthenticated()

      window.addEventListener('focus', handleFocus)

      return () => {
        window.removeEventListener('focus', handleFocus)
      }
    }, [])

    useEffect(() => {
      if (authenticated?.status && isStale && !authenticatedError) {
        refetchUser()
      }
    }, [authenticated, isStale, authenticatedError])

    useEffect(() => {
      if (authenticatedError) {
        removeUser()
      }
    }, [authenticatedError])

    if (status === 'success') {
      return <ComposedComponent {...props} />
    }

    const message = status === 'loading' ? 'Loading the current user\'s information' : 'Fetching the current user\'s information'
    return <Loader message={message} />
  }

  return withRouter(Authenticated)
}

export default isAuthenticated