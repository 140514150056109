import React, { FC } from 'react'
import { Extension } from '../../../../types/FHIRTypes/Extension'
import { Coding as FHIRCoding } from '../../../../types/FHIRTypes/Coding'
import { handleCodingOrExtension } from '../../../../utils/fhirUtils/handleCodingOrExtension'
import { SimpleQuantity as FHIRSimpleQuantity } from '../../../../types/FHIRTypes/SimpleQuantity'

interface SimpleQuantityProps {
    quantity: FHIRSimpleQuantity
}

const SimpleQuantity: FC<SimpleQuantityProps> = ({ quantity }) => {
    return (
        <span>
            {quantity.value}{quantity.unit ? ` ${quantity.unit}` : ''}
        </span>
    )
}

export default SimpleQuantity